exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".page__head.page__head{overflow:visible}.page__head.page__head .grid{overflow:visible}._1jkQe{min-height:100vh}._1un0-{position:fixed;width:100%;z-index:1;border-bottom:1px solid var(--light--base--color);border-bottom:1px solid var(--light--base--color)}._3fabb{z-index:0}._2Ckrx{background-color:#ff0;width:100%;padding:12px 0;text-align:center}", ""]);

// exports
exports.locals = {
	"page": "_1jkQe",
	"page__head": "_1un0-",
	"page__body": "_3fabb",
	"limitedOffer": "_2Ckrx"
};