exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".dW8N-{padding:var(--block--spacing) 0;padding:var(--block--spacing) 0}.dW8N- section{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;padding:var(--block--spacing) 0;padding:var(--block--spacing) 0}.dW8N- section h3{margin:0 0 var(--block--spacing) 0;margin:0 0 var(--block--spacing) 0;text-align:center;font-size:24px;font-size:1.5rem;font-weight:400;font-family:\"Nunito-Sans\",\"Nunito-Light\",\"HelveticaNeue-Light\",sans-serif}.dW8N- section p{margin:0 0 var(--block--spacing) 0;margin:0 0 var(--block--spacing) 0;font-size:17.6px;font-size:1.1rem;color:#666}.dW8N- section a{display:block;font-size:15.2px;font-size:.95rem;text-transform:uppercase;color:#fff;border-radius:5px;padding:7px 28px;background-color:var(--link--color);background-color:var(--link--color);-webkit-box-shadow:0 10px 25px -14px rgba(0,0,0,.65);box-shadow:0 10px 25px -14px rgba(0,0,0,.65)}", ""]);

// exports
exports.locals = {
	"callToAction": "dW8N-"
};