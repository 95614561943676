import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PTs from 'prop-types';
import React from 'react';

import s from './HeadBodyFootPageLayout.scss';

class HeadBodyFootPageLayout extends React.Component {
  static propTypes = {
    // The children is expected to be a render-prop.  See
    // https://reactjs.org/docs/render-props.html for details.  It will be called
    // three times with the region names: 'head', 'body', and 'foot'.  The
    // render-prop should return the components to be rendered in each of these
    // regions.
    bodyClassName: PTs.string,
    // NOTE: The following *className properties will be global classes.  The makes
    // it easy to tweak the layout specific styles without needing to add theme
    // specific styles to HeadBodyFootPageLayout.scss.
    callToActionClassName: PTs.string,
    children: PTs.func.isRequired,
    className: PTs.string,
    footClassName: PTs.string,
    headClassName: PTs.string,
    hideCallToAction: PTs.bool,
    rootClassName: PTs.string,
  };

  static defaultProps = {
    // NOTE: Using BEM naming convention.
    bodyClassName: 'page__body',
    callToActionClassName: 'page__call_to_action',
    className: '',
    footClassName: 'page__foot',
    headClassName: 'page__head',
    hideCallToAction: false,
    rootClassName: 'page',
  };

  constructor(props) {
    super(props);

    this.state = {
      isHeaderTransparent: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll);
    }, 1);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      isHeaderTransparent: window.pageYOffset < 5,
    });
  };

  render() {
    const {
      bodyClassName,
      children,
      callToActionClassName,
      className,
      footClassName,
      headClassName,
      rootClassName,
      hideCallToAction,
    } = this.props;
    return (
      <div className={cx(className, rootClassName, s.page)}>
        <div
          className={cx(headClassName, s.page__head)}
          style={
            this.state.isHeaderTransparent
              ? { background: 'rgba(255,255,255,0)' }
              : { background: 'rgba(255,255,255,0.95)' }
          }
        >
          {children('head')}
        </div>
        <div className={cx(bodyClassName, s.page__body)}>
          {children('body')}
        </div>
        {!hideCallToAction && (
          <div className={cx(callToActionClassName, s.page__call_to_action)}>
            {children('call-to-action')}
          </div>
        )}
        <div className={cx(footClassName, s.page__foot)}>
          {children('foot')}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(HeadBodyFootPageLayout);
