import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PTs from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  faWrench,
  faBars,
  faUsers,
  faBriefcase,
  faPlayCircle,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setVal } from '../../state/actions/all';

import Link from '../Link';

import s from './Navigation.scss';

class Navigation extends React.Component {
  static propTypes = {
    className: PTs.string,
    displayProfileDropDown: PTs.bool,
    setProp: PTs.func.isRequired,
  };

  static defaultProps = {
    className: '',
    displayProfileDropDown: false,
  };

  componentDidMount() {
    if (process.env.BROWSER) {
      document.addEventListener('mousedown', this.handleClick, false);
    }
  }

  componentWillUnmount() {
    if (process.env.BROWSER) {
      document.removeEventListener('mousedown', this.handleClick, false);
    }
  }

  handleClickProfile = () => {
    const { setProp, displayProfileDropDown } = this.props;
    setProp('displayProfileDropDown', !displayProfileDropDown);
  };

  handleClick = e => {
    const { displayProfileDropDown } = this.props;
    if (this.node.contains(e.target)) {
      return;
    }

    if (displayProfileDropDown) {
      this.handleClickProfile();
    }
  };

  hideDropDown = () => {
    const { setProp } = this.props;
    setProp('displayProfileDropDown', false);
  };

  render() {
    const { className, displayProfileDropDown } = this.props;

    return (
      <React.Fragment>
        <nav className={cx(className, s.mainNav, s.largeView)}>
          <Link className={s.link} to="/portfolio">
            portfolio
          </Link>

          <Link className={s.link} to="/solutions">
            services
          </Link>

          <Link className={s.link} to="/team">
            the team
          </Link>

          {/* <Link className={s.link} to="/blog">
            blog
          </Link> */}

          <Link className={s.borderLink} to="/contact">
            get in touch
          </Link>
        </nav>
        <nav
          className={cx(className, s.mainNav, s.smallView)}
          /* eslint-disable-next-line */
          ref={node => (this.node = node)}>
          <button
            className={s.profilePicDropDown}
            onClick={this.handleClickProfile}
            type="button"
          >
            <FontAwesomeIcon className={s.faBarsIcon} icon={faBars} />
          </button>
          <div
            className={cx('profile_dropdown', s.profileDropDown)}
            style={
              displayProfileDropDown ? { right: '0' } : { right: '-300px' }
            }
          >
            <Link
              className={s.link}
              onClick={this.hideDropDown}
              to="/portfolio"
            >
              <FontAwesomeIcon icon={faBriefcase} />
              <span className={s.linkTxt}>Portfolio</span>
            </Link>
            <Link
              className={s.link}
              onClick={this.hideDropDown}
              to="/solutions"
            >
              <FontAwesomeIcon icon={faWrench} />
              <span className={s.linkTxt}>Services</span>
            </Link>
            <Link className={s.link} onClick={this.hideDropDown} to="/team">
              <FontAwesomeIcon icon={faUsers} />
              <span className={s.linkTxt}>The Team</span>
            </Link>
            <Link
              className={s.link}
              onClick={this.hideDropDown}
              to="/how-to-start"
            >
              <FontAwesomeIcon icon={faPlayCircle} />
              <span className={s.linkTxt}>How To Start</span>
            </Link>
            <Link className={s.link} onClick={this.hideDropDown} to="/contact">
              <FontAwesomeIcon icon={faPhone} />
              <span className={s.linkTxt}>Get in Touch</span>
            </Link>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ...state.all,
});

const mapDispatchToProps = dispatch => ({
  setProp: (key, value) => {
    dispatch(setVal(key, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(Navigation));
