import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';

import Link from '../Link';
import Navigation from '../Navigation';
import FluxTechLogo from '../FluxTeckComponents/FluxTechLogo';

import s from './Header.scss';

class Header extends React.Component {
  render() {
    return (
      <header className={s.header}>
        <Link className={s.homeLink} to="/">
          <FluxTechLogo />
        </Link>

        <Navigation />
      </header>
    );
  }
}

export default withStyles(s)(Header);
