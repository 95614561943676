exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._25zDr{position:relative;height:54px;width:54px}._25zDr .xumUF{position:absolute;top:0;left:0;width:54px;height:18px;background-color:rgba(0,128,128,.5)}._25zDr ._3bOPi{position:absolute;bottom:0;left:0;height:36px;width:18px;background-color:rgba(0,128,128,.9)}._25zDr ._3IlqF{position:absolute;top:9px;left:0;width:36px;height:18px;background-color:rgba(0,128,128,.75)}", ""]);

// exports
exports.locals = {
	"root": "_25zDr",
	"horizontalRect": "xumUF",
	"verticalRect": "_3bOPi",
	"halfRect": "_3IlqF"
};