import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';

import Link from '../Link';

import {
  Col,
  Grid,
  Row,
} from '../../incubation/components/ResponsiveGridLayout';

import s from './CallToAction.scss';

class CallToAction extends React.Component {
  render() {
    return (
      <div className={s.callToAction}>
        <Grid>
          <Row>
            <Col>
              <section>
                <h3>Want help growing your business?</h3>
                <Link to="/contact">Get in Touch</Link>
              </section>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(s)(CallToAction);
