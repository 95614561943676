import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';
import cx from 'classnames';

import {
  faFacebookSquare,
  faLinkedin,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../Link';

import {
  Col,
  Grid,
  Row,
} from '../../incubation/components/ResponsiveGridLayout';

import FluxTechLogo from '../FluxTeckComponents/FluxTechLogo/FluxTechLogo';

import s from './Footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <Grid>
          <Row>
            <Col md={6} sm={12}>
              <div className={s.branding}>
                <div className={s.logo}>
                  <FluxTechLogo />
                </div>
                <p className={s.companyName}>Flux Tech</p>
              </div>
              <p className={s.text}>Robust websites and mobile applications</p>
              <span className={s.copyright}>© Flux Tech</span>

            </Col>
            <Col md={6} sm={12}>
              <h3 className={s.sitemap}>Sitemap</h3>
              <div className={s.linkContainer}>
                <Link className={s.link} to="/">
                  Home
                </Link>
                <span className={s.spacer}>·</span>
                <Link className={s.link} to="/portfolio">
                  Portfolio
                </Link>
                <span className={s.spacer}>·</span>
                <Link className={s.link} to="/services">
                  Services
                </Link>
                {/* <Link className={s.link} to="/blog">
                  Blog
                </Link> */}
                <span className={s.spacer}>·</span>
                <Link className={s.link} to="/team">
                  The Team
                </Link>
                <span className={s.spacer}>·</span>
                <Link className={s.link} to="/full-sitemap">
                  More
                </Link>
              </div>
              <h3 className={s.contactInfo}>Contact Info</h3>
              <div className={s.contactContainer}>
                <div className={s.details}>
                  <p>(719) 339-5772</p>
                  <p>hello@fluxtech.co</p>
                </div>
              </div>
              <h3 className={s.contactInfo}>Socials</h3>
              <div className={s.contactContainer}>
                <div className={s.social}>
                  <a
                    className={s.socialLink}
                    href="https://www.linkedin.com/in/jonathan-emig-5290b5119"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      className={s.socialIcon}
                      icon={faLinkedin}
                    />
                  </a>
                  <a
                    className={s.socialLink}
                    href="https://www.youtube.com/@jonathanemig"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      className={s.socialIcon}
                      icon={faYoutube}
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <script
              src="https://widget.clutch.co/static/js/widget.js"
              type="text/javascript"
            />
            <div className={s.badge}>
              <div
                className={cx(s.clutchWidget, 'clutch-widget')}
                data-clutchcompany-id="942721"
                data-darkbg="1"
                data-height="50"
                data-url="https://widget.clutch.co"
                data-widget-type="2"
              />
            </div>
          </Row>
        </Grid>
      </footer>
    );
  }
}

export default withStyles(s)(Footer);
