import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';

import s from './FluxTechLogo.scss';

const FluxTechLogo = () => (
  <div className={s.root}>
    <div className={s.horizontalRect} />
    <div className={s.verticalRect} />
    <div className={s.halfRect} />
  </div>
);

export default withStyles(s)(FluxTechLogo);
