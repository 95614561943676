exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3YJyY, ._1KVS-, ._3fZ5d, .Docvr, ._32GoD{overflow:auto;z-index:0}._1KVS-{z-index:3;-webkit-transition:300ms ease-in-out;transition:300ms ease-in-out}._3YJyY{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._3fZ5d{-ms-flex:1 0 auto;flex:1 0 auto}._32GoD{position:relative}.Docvr{background-color:#333;padding:var(--block--spacing--lg) 0;padding:var(--block--spacing--lg) 0;position:relative;z-index:2}", ""]);

// exports
exports.locals = {
	"page": "_3YJyY",
	"page__head": "_1KVS-",
	"page__body": "_3fZ5d",
	"page__foot": "Docvr",
	"page__call_to_action": "_32GoD"
};