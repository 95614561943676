exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3ZyJv{position:relative;width:100%;height:80px;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}._3ZyJv .rbUjk{height:54px;margin:13px 0}", ""]);

// exports
exports.locals = {
	"header": "_3ZyJv",
	"homeLink": "rbUjk"
};