/* eslint-disable css-modules/no-unused-class */
import cx from 'classnames';
import PTs from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';

import s from './Col.scss';

const Col = ({
  children,
  lg,
  lgOffset,
  lgOrder,
  md,
  mdOffset,
  mdOrder,
  sm,
  smOffset,
  smOrder,
  xl,
  xlOffset,
  xlOrder,
  xs,
  xsOffset,
  xsOrder,
}) => {
  const classNames = [];

  if (xsOffset) {
    classNames.push(s[`offset-${xsOffset}`]);
  }

  if (xsOrder !== null) {
    classNames.push(s[`order-${xsOrder}`]);
  }

  if (xs === true) {
    classNames.push(s.col);
  } else if (xs !== null) {
    classNames.push(s[`col-${xs}`]);
  }

  if (smOffset !== null) {
    classNames.push(s[`offset-sm-${smOffset}`]);
  }

  if (smOrder !== null) {
    classNames.push(s[`order-sm-${smOrder}`]);
  }

  if (sm === true) {
    classNames.push(s['col-sm']);
  } else if (sm !== null) {
    classNames.push(s[`col-sm-${sm}`]);
  }

  if (mdOffset !== null) {
    classNames.push(s[`offset-md-${mdOffset}`]);
  }

  if (mdOrder !== null) {
    classNames.push(s[`order-md-${mdOrder}`]);
  }

  if (md === true) {
    classNames.push(s['col-md']);
  } else if (md !== null) {
    classNames.push(s[`col-md-${md}`]);
  }

  if (lgOffset !== null) {
    classNames.push(s[`offset-lg-${lgOffset}`]);
  }

  if (lgOrder !== null) {
    classNames.push(s[`order-lg-${lgOrder}`]);
  }

  if (lg === true) {
    classNames.push(s['col-lg']);
  } else if (lg !== null) {
    classNames.push(s[`col-lg-${lg}`]);
  }

  if (xlOffset !== null) {
    classNames.push(s[`offset-xl-${xlOffset}`]);
  }

  if (xlOrder !== null) {
    classNames.push(s[`order-xl-${xlOrder}`]);
  }

  if (xl === true) {
    classNames.push(s['col-xl']);
  } else if (xl !== null) {
    classNames.push(s[`col-xl-${xl}`]);
  }

  if (classNames.length === 0) {
    classNames.push(s.col);
  }
  return (
    <div className={cx(classNames)} role="presentation">
      {children}
    </div>
  );
};

// prettier-ignore
const COL_PROP_WIDTH_SHAPE = PTs.oneOf([
  'auto',
  true,
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
]);
const COl_PROP_OFFSET_SHAPE = PTs.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
// prettier-ignore
const COl_PROP_ORDER_SHAPE = PTs.oneOf([
  'first',
  'last',
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
]);
Col.propTypes = {
  children: PTs.node,
  lg: COL_PROP_WIDTH_SHAPE,
  lgOffset: COl_PROP_OFFSET_SHAPE,
  lgOrder: COl_PROP_ORDER_SHAPE,
  md: COL_PROP_WIDTH_SHAPE,
  mdOffset: COl_PROP_OFFSET_SHAPE,
  mdOrder: COl_PROP_ORDER_SHAPE,
  sm: COL_PROP_WIDTH_SHAPE,
  smOffset: COl_PROP_OFFSET_SHAPE,
  smOrder: COl_PROP_ORDER_SHAPE,
  xl: COL_PROP_WIDTH_SHAPE,
  xlOffset: COl_PROP_OFFSET_SHAPE,
  xlOrder: COl_PROP_ORDER_SHAPE,
  xs: COL_PROP_WIDTH_SHAPE,
  xsOffset: COl_PROP_OFFSET_SHAPE,
  xsOrder: COl_PROP_ORDER_SHAPE,
};

Col.defaultProps = {
  children: null,
  lg: null,
  lgOffset: null,
  lgOrder: null,
  md: null,
  mdOffset: null,
  mdOrder: null,
  sm: null,
  smOffset: null,
  smOrder: null,
  xl: null,
  xlOffset: null,
  xlOrder: null,
  xs: null,
  xsOffset: null,
  xsOrder: null,
};

export default withStyles(s)(Col);
