import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PTs from 'prop-types';
import React from 'react';

import Header from '../Header';
import Footer from '../Footer';
import CallToAction from '../CallToAction';

import HeadBodyFootPageLayout from '../../incubation/components/HeadBodyFootPageLayout';

import {
  Col,
  Grid,
  Row,
} from '../../incubation/components/ResponsiveGridLayout';

// external-global styles must be imported in your JS.
import mainCss from '../../styles/main.scss';

import s from './PageLayout.scss';

class PageLayout extends React.Component {
  static propTypes = {
    children: PTs.node.isRequired,
    className: PTs.string,
    hideCallToAction: PTs.bool,
  };

  static defaultProps = {
    className: '',
    hideCallToAction: false,
  };

  renderRegion = region => {
    const { children } = this.props;
    if (region === 'head') {
      return (
        <div>
          <div className={s.limitedOffer}>
            <b>Limited time offer!</b> Static websites for $199
          </div>
          <Grid>
            <Row>
              <Col>
                <Header />
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    if (region === 'foot') {
      return (
        <Grid>
          <Row>
            <Col>
              <Footer />
            </Col>
          </Row>
        </Grid>
      );
    }
    if (region === 'call-to-action') {
      return <CallToAction />;
    }
    return children;
  };

  render() {
    const { className, hideCallToAction } = this.props;
    return (
      <HeadBodyFootPageLayout
        bodyClassName={cx('page__body', s.page__body)}
        footClassName="page__foot"
        headClassName={cx('page__head', s.page__head)}
        hideCallToAction={hideCallToAction}
        rootClassName={cx(className, 'page', s.page)}
      >
        {this.renderRegion}
      </HeadBodyFootPageLayout>
    );
  }
}

export default withStyles(mainCss, s)(PageLayout);
