import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import history from '../../../client/history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    to: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onClick: null,
  };

  handleClick = event => {
    const { onClick, setProp } = this.props;
    if (onClick) {
      onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    if (setProp) {
      setProp('displayProfileDropDown', false);
    }

    event.preventDefault();
    history.push(this.props.to);
  };

  render() {
    /* eslint-disable-next-line */
    const { to, children, setProp, ...props } = this.props;
    return (
      <a href={to} {...props} onClick={this.handleClick}>
        {children}
      </a>
    );
  }
}

const mapStateToProps = state => ({ ...state.misc });

const mapDispatchToProps = dispatch_unused => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Link);
